@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.container {
  position: fixed;

  top: 0;
  left: 0;
  right: 0;

  background-color: white;

  z-index: 1000;

  height: 72px;

  transition: box-shadow 0.3s;

  &--shadow {
    box-shadow: 0px 4px 36px rgba(196, 196, 196, 0.24);
  }
}

.wrapper {
  margin: 0 40px;
  height: 100%;
}

.content {
  gap: 80px;

  max-width: 1060px;
  height: 100%;

  margin: 0 auto;
}

.logo {
  background: url('/static/components/NavigationBar/logo-desktop.svg');
  background-repeat: no-repeat;
  width: 136px;
  height: 33px;
}

.logoWrapper:focus-visible {
  outline: 2px solid $ds-grey-900;
}

.button {
  height: 40px !important;
  line-height: 16px !important;

  margin-right: 0;
}

.linkContainer {
  display: flex;

  & > * {
    display: inline-block;
    margin-right: 48px;

    &:last-child {
      margin-right: 0;
    }
  }
}

/* The Business navigation menu has no login button and no language switcher.
To keep the link section centered, we add padding equal to the width of the logo button */
.b2bLinkContainer {
  padding-right: 136px;
}

.productLinkContainer {
  position: relative;

  & > div {
    display: none;
  }

  &:hover {
    & > div {
      display: block;
    }

    & > .link {
      color: $ds-purple-500;
    }
  }
}

.innerLink {
  outline-offset: 4px;
}

// If the Product menu item includes categories, the position of the dropdown menu shouldn't depend on the position of the button that opens it.  */
.productLinkContainerWithCategories {
  position: static;
}

.link {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  color: $ds-grey-900;
  padding: 24px 0;
  background-color: transparent;
  border: none;
  margin-top: 0;
  margin-bottom: 0;

  &:focus-visible {
    outline: none;

    .innerLink {
      outline: 2px solid $ds-grey-900;
    }
  }

  &:hover,
  &Active {
    color: $ds-purple-500;
    &::before {
      content: '';
      position: absolute;
      top: 48px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 10px;
      background: $ds-purple-500;
      width: 16px;
      height: 4px;
    }
  }
}

@keyframes show-animation {
  from {
    opacity: 0;
    display: none;
  }

  to {
    display: block;
    opacity: 1;
  }
}

@keyframes hide-animation {
  from {
    opacity: 1;
  }

  to {
    display: none;
    opacity: 0;
  }
}

.rightButtonContainer {
  position: relative;
}

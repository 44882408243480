
@import '@popsure/dirty-swan/dist/grid';

.container {
  @media (max-width: 800px) {
    margin: 16px 0 32px;
  }

  @include p-size-mobile {
    a {
      display: inline-block;
      margin-top: 8px;
    }
  }
}
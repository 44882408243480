@import '@popsure/dirty-swan/dist/grid';

@include p-size-mobile {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}

@include p-size-tablet {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}

@include p-size-desktop {
  .desktop {
    display: block;
  }
  .mobile {
    display: none;
  }
}

[data-aos='animate-underline'] {
  width: 0;
  transition-property: transform, width;

  &.aos-animate {
    width: 138px;
  }
}

[data-aos='animate-width'] {
  width: 0;
  transition-property: transform, width;
  transition-delay: 0.3s;

  &.aos-animate {
    width: 100%;
  }
}

@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.container {
  background-color: $ds-grey-100;
  height: 100%;
  padding: 56px 0 32px 0;
  margin-top: 96px;

  @include p-size-mobile {
    padding: 32px;
    margin-top: 56px;
  }
}

.logo {
  background: url('./img/logo.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 136px;
  height: 32px;
}

.divider {
  height: 2px;
  background-color: $ds-grey-200;
  border-radius: 8px;
}

.contentContainer {
  @media (max-width: 800px) {
    flex-direction: column;
  }
}

.legalLink {
  text-decoration: none;
  color: $ds-grey-500;
  font-weight: 700;
  margin-right: 40px;

  &:hover {
    color: $ds-primary-500;
  }

  @media (max-width: 800px) {
    margin-right: 0;
  }
}

.languageSwitcherContainer {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 800px) {
    align-items: flex-start;
    flex-direction: column-reverse;
  }
}

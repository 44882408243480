@import '@popsure/dirty-swan/dist/colors';

.logInButton {
  position: relative;

  text-decoration: none;

  color: $ds-grey-900;
  text-align: right;

  &:hover {
    color: $ds-primary-500;

    .loginIcon {
      display: none;
    }

    .loginIconHover {
      display: inline-block;
    }
  }

  &:focus-visible {
    outline: 2px solid $ds-grey-900;
    outline-offset: 6px;
  }
}

@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.container {
  gap: 64px;
  max-width: 1060px;

   @media (max-width: 800px) {
    grid-template-columns: repeat(
      1,
      1fr
    ) !important; // overrides dynamic inline css on desktop view
    gap: 0;
    padding-bottom: 0;
  }
}

.header {
   @media (max-width: 800px) {
    margin-top: 32px;
  }
}

.link {
  text-decoration: none;
  color: $ds-grey-600;

  &:hover {
    color: $ds-primary-500;
  }
}

.socialMediaLinksContainer {
   @media (max-width: 800px) {
    width: 128px;
  }
}

.divider {
  height: 1px;
  background-color: $ds-grey-300;
}

.hiddenOnMobile {
  display: block;
  @include p-size-mobile {
    display: none;
  }
}

@import '@popsure/dirty-swan/dist/colors';

@keyframes appear-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.dropdown {
  position: absolute;
  top: 72px;

  background-color: white;
  box-shadow: 0px 4px 36px rgba(196, 196, 196, 0.24);

  animation-name: appear-in;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: ease;
}

.menuWithCategoriesContainer {
  width: 100vw;
  max-width: 100%;

  transform: translateX(-50%);
  left: 50%;

  > ul {
    max-width: 1060px;
  }
}

.category {
  width: 227px;
}

.categoryTitle {
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.5px;
}

.categoryHeader {
  gap: 12px;
  margin-bottom: 12px;
}

.divider {
  height: 1px;
  background-color: $ds-grey-300;
}

.productContainer {
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.5px;

  color: $ds-grey-700;

  &:focus-visible {
    outline: 2px solid $ds-grey-900;
    outline-offset: 4px;
  }

  &:hover {
    color: $ds-purple-500;
  }
}

.bannerCard {
  width: 264px;
  height: max-content;
}

.companyLink {
  gap: 4px;
}

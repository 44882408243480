@import '@popsure/dirty-swan/dist/colors';

@keyframes appear-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.dropdown {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 72px;

  background-color: white;
  box-shadow: 0px 4px 36px rgba(196, 196, 196, 0.24);

  animation-name: appear-in;
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-timing-function: ease;
}

.productContainer {
  text-decoration: none;
  color: $ds-grey-700;

  &:hover {
    color: $ds-primary-500;
  }

  &:focus-visible {
    outline: 2px solid $ds-grey-900;
    outline-offset: 4px;
  }
}

.productTitle {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-wrap: nowrap;

  color: $ds-grey-700;

  &:hover {
    color: $ds-purple-500;
  }
}

.separator {
  border-top: 1px solid $ds-grey-300;
  border-bottom: none;
  border-left: none;
  border-right: none;

  margin-top: 24px;
  margin-bottom: 24px;
}

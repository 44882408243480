@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.dropdownButton {
  position: relative;
  color: $ds-grey-700;
  background-color: $ds-white;
  letter-spacing: 0.5px;
  padding: 12px;

  &:focus {
    box-shadow: 0 0 0 1px #fff, 0 0 0 3px $ds-grey-900;
  }
}

.icon {
  width: 12px;
  height: 8px;
  background: url('/static/icons/chevron-down.svg') no-repeat;
}

.condensedButton {
  font-weight: 700;
  height: 30px;
}

.normalButton {
  font-weight: initial;
  padding: 12px 16px;
}

.dropdownContainer {
  position: absolute;
  top: 64px;
  left: 16px;
  width: calc(100vw - 32px);

  @include p-size-desktop {
    top: 56px;
    left: auto;
    right: 0;
    width: auto;
    min-width: 440px;
  }

  background-color: white;

  z-index: 1000;
  box-shadow: 0px 4px 36px rgba(196, 196, 196, 0.24);

  &TopOverlay {
    bottom: 56px;
    top: auto;
    left: 0;
    right: auto;
  }

  button {
    display: block;
    height: 48px;
    width: 100%;
    padding: 12px 16px;

    background-color: transparent;
    color: $ds-grey-900;
    letter-spacing: 0.5px;
    text-align: left;

    cursor: pointer;

    &:hover {
      background-color: $ds-purple-100;
    }
  }

  label {
    max-width: 736px; // align with DS Radio
    @include p-size-desktop {
      width: 184px;
    }
  }
}

.languageOption {
  @include p-size-desktop {
    display: flex;
    gap: 8px;
  }
}

.countryContainer {
  height: auto;
  max-height: 328px;
  overflow-y: scroll;
  scrollbar-width: none;
}

.countryLabel {
  flex-direction: row !important;
  justify-content: start;
  align-items: center;
  gap: 4px;
}

.countryOption {
  width: 100%;

  @include p-size-desktop {
    width: auto;
  }
}

.description {
  margin-top: 4px;
}
